import React from 'react';
import partnersConfig from 'config/partners';
import urlUtils from 'utils/urlUtils';

import { LogoGridContainer, PartnerLink } from '../styles';

const logos = partnersConfig.map(({ image, name, link }) => (
	<PartnerLink target="_blank" href={link}>
		<img
			src={urlUtils.appendQueryParams(image, `auto=compress&auto=format&w=160&h=160`)}
			alt={name}
		/>
	</PartnerLink>
));

export const LogoGrid = () => {
	return <LogoGridContainer>{logos}</LogoGridContainer>;
};
