import React from 'react';

import { Helmet } from 'react-helmet';

import Header from 'components/common/header';
import Main from 'components/common/main';
import Footer from 'components/common/footer';
import { PageMeta } from 'constants/page_meta';
import { TitleBar } from 'lib/TitleBar';
import {
	PageContainer,
	SectionTitle,
	ContentText,
	BoldText,
	StaticList,
	SectionSubTitle,
	Spacer,
} from './styles';
import ContactForm from 'components/common/contact-form';
import { LogoGrid } from './logo-grid';
import { ContactUsTypes } from 'components/contact-us/contactUsTypes';
import ContactUsForm from 'components/contact-us/ContactUsForm';

const meta = PageMeta.getStatic('partners');

const Partners = () => {
	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header searchable />
			<TitleBar title="Our Partners"></TitleBar>
			<PageContainer>
				<SectionTitle>Our partnered educational institutions</SectionTitle>
				<ContentText>
					We’ve travelled far and wide battling the humid Kwazulu-Natal air, the Cape Town
					hill-starts and occasionally long walks in heels through Sandton to bring together a
					collection of the most outstanding college and university partners. We’ve partnered with
					each of these colleges and universities over the last three years to help them connect
					their students with accommodation.
				</ContentText>

				<LogoGrid />

				<ContentText>
					We know that your college days are more than the books you read and the lectures you
					attend, they’re also about the
					<BoldText> human connection.</BoldText> They’re about late-night cramming for the test at
					8am; they’re about crazy times out in town at your favourite bar; they’re about morning
					coffee with your best friend and about having real talks with the people you grow to love.
				</ContentText>
				<ContentText>It’s about the connection. And that’s our obsession.</ContentText>
				<SectionTitle>Why partner with us?</SectionTitle>
				<ContentText>
					While crossing the country (read above) we couldn’t help but throw some parties, host the
					odd t-rex race and graffiti installation. It was through these wacky activities that we
					got to really know the students we interact with on a daily basis. We got to chat to them
					about their challenges finding suitable accommodation and what their dream home would look
					like.
				</ContentText>

				<ContentText indent>
					1. DigsConnect has become a collection of these homes. One central place that students
					(and now young adults) can visit to compare all available options, and chat directly with
					the landlord.
				</ContentText>
				<ContentText indent>
					2. There is also a filter on every single listing that calculates the distance from the
					property to the college of your choice.
				</ContentText>
				<ContentText indent>
					3. We know that options alone aren’t enough. There are too many scammers out there, and we
					knew we needed to do more. So we have a dedicated support team who call every user that
					lists their property on our platform to get to know them (and their property), we validate
					their cell phone numbers with an OTP, their email address and even collect their ID
					numbers before their property can go live on our platform. That said, we do still
					encourage students to ask questions and to visit the property before making any formal
					commitment.
				</ContentText>
				<ContentText indent>
					4. Finding a new home is a big step, and you shouldn’t need to take it alone! We have a
					full-time team that is there to support (and root for you!) along the journey. Don’t get
					surprised when they call or WhatsApp you to ask how that viewing went, where we could
					provide some support, or act as a sounding board before making any big decision.
				</ContentText>

				<SectionTitle>What does it mean to partner with DigsConnect?</SectionTitle>

				<ContentText>We have two different kinds of partnerships:</ContentText>

				<SectionSubTitle>1. Brand partnership</SectionSubTitle>

				<ContentText>This partnership is all about getting to know each other -</ContentText>

				<StaticList indent={1}>
					<li>
						We’ll give you DigsConnect posters and flyers to distribute around your campus(s).
					</li>
					<li>
						We’d swap links the good old fashioned way - featuring each other on our respective
						websites.
					</li>
					<li>
						We’ll even show up and spread some DigsConnect love activation-style for open days and
						orientation weeks if you’d like.
					</li>
				</StaticList>

				<SectionSubTitle>2. Integrated partnership</SectionSubTitle>

				<ContentText>Think brand partnership plus:</ContentText>

				<StaticList indent={1}>
					<li>We’ve got the flyers and the website features (above).</li>
					<li>We’re also going to show up for activations at open days and orientation weeks.</li>
					<li>
						In an integrated partnership though, future students will have the opportunity to check
						a box on your college application form to automatically get signed up to our platform.
						We’ll then get in contact with the students directly to help them find their dream home.
						Taking the stress off your team.
					</li>
				</StaticList>

				<ContentText>
					We’re constantly on the lookout for new colleges and tertiary institutions to join the
					DigsConnect family so if you’re keen to give your students a leg up in the search for
					student accommodation, drop us an email/message! We’d love to hear from you.
				</ContentText>

				<SectionTitle>The cost</SectionTitle>
				<ContentText>Nope. No costs involved.</ContentText>

				<SectionSubTitle>Keen to become a partner?</SectionSubTitle>

				<Spacer>
					<ContactUsForm givenReason={ContactUsTypes.Partner} formOnly />
				</Spacer>

				<ContentText>Need more info?</ContentText>
				<ContentText>
					Send an email to
					<a href="mailto:hello@digsconnect.com"> hello@digsconnect.com</a>, give us a call on
					<a href="tel:0717967343"> 071 796 7343 </a>
					or visit our 'About us' page.
				</ContentText>
			</PageContainer>
			<Footer />
		</Main>
	);
};

export default Partners;
